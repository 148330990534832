<template>
  <div class="pagination" v-if="meta && meta.last_page > 1">
    <div class="container">
      <div class="row">
        <div class="pagination__list">
          <div class="pagination__prev" v-if="meta.current_page != 1">
            <a href="#" @click.prevent="toPrevPage">
              <inline-svg :src="require(`@/assets/images/svg/arrow-next.svg`)"></inline-svg>
            </a>
          </div>
          <div class="pagination__item" v-for="item in pageList" :key="item">
            <a href="#" :class="item === meta.current_page ? 'active' : null" @click.prevent="changePage(item)">{{item}}</a>
          </div>
          <div class="pagination__next" v-if="meta.current_page != meta.last_page">
            <a href="#" @click.prevent="toNextPage">
              <inline-svg :src="require(`@/assets/images/svg/arrow-next.svg`)"></inline-svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import PaginationMixin from '../../mixins/pagination';
export default {
  props: ['meta'],
  mixins: [PaginationMixin],
  data(){
    return{
      countShowPages: 8,
      lastVisiblePage: 1,
      pageList:[]
    }
  },
  created() {
    this.getLestVisiblePage();
  },
  watch:{
    page(e){
      this.$emit('changeCurrentPage', e)
    }
  },
  methods:{
    getLestVisiblePage(){
      if(this.meta){
        for(let i = this.meta.current_page; i <= this.meta.last_page; i++){
          this.pageList.push(i)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.pagination{
  margin: 40px 0;
  width: 100%;
  &__list{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__item{
    height: 35px;
    width: 35px;
    a{
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      font-family: 'Inter', Arial;
      font-weight: 400;
      color: $grey;
      font-size: 20px;
      &:focus{}
      text-decoration: none;
      &.active{
        border-radius: 50%;
        background-color: $yellow;
        color: white;
        font-weight: 900;
      }
    }
    p{
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin: 0;
      font-family: 'Inter', Arial;
      color: $grey;
      font-size: 20px;
      font-weight: 400;
    }
  }
  &__next{
    margin-left: 30px;
  }
  &__prev{
    transform: rotate(180deg);
  }
}
</style>
